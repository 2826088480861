import * as React from "react"
import { Container, Carousel } from "react-bootstrap" 
import { StaticImage } from "gatsby-plugin-image"

function OurReviews() {
	return (
        <div className="reviews">
            <div className="reviews-content">
       
                <h1>Find What Our Clients Say About Us</h1>
               
                <div className="row">
               
                    <div className="col-sm-4">

                    </div>
                    <div className="col-sm-8">
                    <div id="google-reviews">
                    <div class="elfsight-app-8f50dbf5-c6b5-4e03-8c51-0fce73d33925"></div>
                    </div>
                   <div id="tripadvisor-reviews">
                  
                   <div class="elfsight-app-2328122e-ca99-4d80-afb9-92af1e8ecabd"></div>
                   </div>
                    </div>
                   
                </div>
                
            </div>
            
     
        </div>


    );
}

export default OurReviews;