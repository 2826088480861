import * as React from "react"
import { Link } from "gatsby"
import * as styles from "../css/styles.scss"
import { StaticImage } from "gatsby-plugin-image"
import "./home/home.scss"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Hero from "./home/components/hero-main"
import AllTours from "./home/components/alltours"
import Info from "./home/components/info"
import OurReviews from "./home/components/reviews"
import OurBlog from "./home/components/blog"

const IndexPage = () => (
  <Layout>
    <Seo title="Home" />
    <Hero/>
    <AllTours/>
    <Info/>
    <OurBlog/>
    <OurReviews/>
    
  
  </Layout>
)

export default IndexPage
